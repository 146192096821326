<template>
  <vue-draggable-resizable
    v-if="visible"
    :draggable="true"
    :resizable="false"
    :x="getDragebleStlye().x"
    :y="getDragebleStlye().y"
    :parent="true"
    :z="9999"
    style="border:none;width:max-content;height:max-content"
  >
    <div
      @mousedown.prevent
      @mouseover="topCenterMouseover" 
      @mouseout="topCenterMouseout"
      class="move-img-modal"
      @wheel="moveModalWheelChange"
      :style="`height:auto;width:max-content`"
    >
      <img
        id="video-image"
        :style="{
          userSelect: 'none',
          pointerEvents: 'none',
          width: width + 'px',
          height: 'auto'
        }"
        @click.stop
        :src="url"
        alt=""
      />
      <div class="image-action" @click.stop>
        <div><a-icon type="zoom-in" @click="scale('amp')" /></div>
        <div><a-icon type="zoom-out" @click="scale('reduce')" /></div>
        <div><a-icon style="font-size:15px;" @click="handleCancel" type="close-circle" /></div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
export default {
  props: {
    isSys: {
      type: Boolean,
      default: false
    },
    innerWidth: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
      url: '',
      imgShow: false,
      width: 200,
      videoShow: false,
      color: '',
      angle: 0,
      dragData: null
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    }
  },
  methods: {
    topCenterMouseover(){
      this.$emit('topCenterMouseover')
    },
    topCenterMouseout(){
      this.$emit('topCenterMouseout')
    },
    getDragebleStlye() {
      let x = (this.innerWidth - 200) / 2
      let y = 0
      return {
        x,
        y
      }
    },
    handleDragStart(event) {
      this.dragData = {
        x: event.clientX,
        y: event.clientY
      }
      event.dataTransfer.setData('text/plain', '')
    },
    handleDragEnd(event) {
      this.dragData = null
    },
    moveModalWheelChange(e) {
      if (e.deltaY < 0) {
        this.width += 20
      } else {
        this.width -= 20
        if (this.width < 120) {
          this.width = 120
        }
      }
    },
    scale(type) {
      if (type == 'amp') {
        this.width += 20
      } else {
        this.width -= 20
        if (this.width < 120) {
          this.width = 120
        }
      }
    },
    open(url) {
      this.url = url
      this.width = 200
      this.imgShow = true
      this.visible = true
      this.videoShow = false
    },
    rorate(type) {
      if (type == 'left') {
        this.angle = this.angle - 90
      } else {
        this.angle = this.angle + 90
      }
    },
    handleCancel() {
      this.url = ''
      this.imgShow = false
      this.videoShow = false
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="less">
.move-img-modal {
  position: relative;
  /* z-index: 9999; */
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
  img {
    -webkit-user-drag: none; /* Chrome, Safari, Opera */
    user-select: none; /* Standard syntax */
    -ms-user-select: none; /* IE10+ */
    -moz-user-select: none; /* Firefox */
    -khtml-user-select: none;
  }
}
.image-action {
  position: absolute;
  bottom: -35px;
  left: calc(50% - 90px);
  width: 180px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  line-height: 30px;
  font-size: 16px;
  justify-content: space-between;
  color: #fff;
  > div {
    &:hover {
      > .anticon {
        transition: 0.3s all;
        cursor: pointer;
        color: var(--theme-color);
      }
    }
  }
}
</style>
