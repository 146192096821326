var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "vue-draggable-resizable",
        {
          staticStyle: {
            border: "none",
            width: "max-content",
            height: "max-content",
          },
          attrs: {
            draggable: true,
            resizable: false,
            x: _vm.getDragebleStlye().x,
            y: _vm.getDragebleStlye().y,
            parent: true,
            z: 9999,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "move-img-modal",
              style: `height:auto;width:max-content`,
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                },
                mouseover: _vm.topCenterMouseover,
                mouseout: _vm.topCenterMouseout,
                wheel: _vm.moveModalWheelChange,
              },
            },
            [
              _c("img", {
                style: {
                  userSelect: "none",
                  pointerEvents: "none",
                  width: _vm.width + "px",
                  height: "auto",
                },
                attrs: { id: "video-image", src: _vm.url, alt: "" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              }),
              _c(
                "div",
                {
                  staticClass: "image-action",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("a-icon", {
                        attrs: { type: "zoom-in" },
                        on: {
                          click: function ($event) {
                            return _vm.scale("amp")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("a-icon", {
                        attrs: { type: "zoom-out" },
                        on: {
                          click: function ($event) {
                            return _vm.scale("reduce")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("a-icon", {
                        staticStyle: { "font-size": "15px" },
                        attrs: { type: "close-circle" },
                        on: { click: _vm.handleCancel },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }